<template>
  <div class="jd_zy">
    <div class="zuoye_list" v-loading="loading">
      <div class="close">
        <i class="el-icon-circle-close" @click="closeAction"></i>
      </div>
      <div :class="[
        'stu',
        {
          you: item.jibie >= 90,
          liang: item.jibie >= 60 && item.jibie < 90,
          cha: item.jibie < 60,
        },
      ]" v-for="item in stus" :key="item.id">
        <h3>{{ item.stu_name }}</h3>
        <p>{{ item.kecheng_name }}</p>
        <el-input placeholder="进度" v-model="item.jibie" clearable size="mini" :readonly="true"></el-input>
      </div>
    </div>
    <div class="pingluns" style="padding: 0 25px;">
      <h3 style="padding: 15px 0;font-size: 20px;color: #555;">作业评论</h3>
      <el-card class="box-card">
        <div v-for="item in stus" :key="item.id" class="text item">
          <el-row class="infor" v-if="item.des">
            <el-col :span="5">{{ item.zuoye_name }}</el-col>
            <el-col :span="2">{{ item.date }}</el-col>
            <el-col :span="18">{{ item.des }}</el-col>
          </el-row>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "StuList",
  data() {
    return {
      stus: [],
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    //获取所有的作业信息
    let row = this.$route.query.row;
    this.$datas.meta = { ...row };
    let res = await this.$datas.getZuoYeByStu;
    let datas = res.data;
    for (let i in datas) {
      for (let j in datas[i].zyj) {
        if (datas[i].zyj[j].stu_name == row.stu_name) {
          this.stus.push({
            stu_name: row.stu_name,
            kecheng_name: datas[i].kecheng_name,
            jieduan_name: datas[i].jieduan_name,
            jibie: datas[i].zyj[j].jibie,
            date: datas[i].zyj[j].des_date,
            des: datas[i].zyj[j].des,
            zuoye_name: datas[i].zuoye_name,
          });
          break;
        }
      }
    }
    this.loading = false;
  },
  methods: {
    closeAction() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.zuoye_list {
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;

  .close {
    padding: 10px 20px;
    text-align: right;

    i {
      font-size: 25px;
      cursor: pointer;
      color: #555;

      &:hover {
        color: #aaff;
      }
    }
  }

  .stu {
    width: 120px;
    padding: 5px 10px;
    border: 1px solid #999;
    border-radius: 5px;
    float: left;
    margin: 10px;

    h3 {
      margin-bottom: 5px;
      cursor: pointer;
    }

    p {
      padding: 0 0 5px;
    }
  }

  .liang {
    background-color: #409eff;
    color: white;
    border: 1px solid #409eff;
  }

  .cha {
    background-color: #f56c6c;
    color: white;
    border: 1px solid #f56c6c;
  }
}

.infor {
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: #555;
}
</style>
